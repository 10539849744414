import React, { useEffect } from "react";
import { Fragment } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import {
    ChatAltIcon,
    CodeIcon,
    DotsVerticalIcon,
    EyeIcon,
    FlagIcon,
    PlusSmIcon,
    SearchIcon,
    ShareIcon,
    StarIcon,
    HeartIcon,
    ChevronDoubleRightIcon,
    TrashIcon,
    PencilAltIcon,
    ArchiveIcon
} from "@heroicons/react/solid";
import {
    BellIcon,
    FireIcon,
    HomeIcon,
    MenuIcon,
    TrendingUpIcon,
    UserGroupIcon,
    XIcon,
} from "@heroicons/react/outline";
import { axiosInstance } from "../../utils/axios";
import { useState, createContext, useContext } from "react";
import { getUser } from "../../utils/storage";
import ConfirmModal from "../modals/ConfirmModal";
import PostModal from "../modals/PostModal";
import { useTranslation } from "react-i18next";

const userLogged = getUser();
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function Post(props) {
    const [user, setUser] = useState([]);
    const [liked, setLiked] = useState(false);
    const [favorite, setFavorite] = useState(false);
    const [matched, setMatched] = useState(null);
    const [archived, setArchived] = useState(props.archived);
    const [postFavorite, setPostFavorite] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPostModal, setShowPostModal] = useState(false);
    const [tags, setTags] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // const [postGallery, setPostGallery] = useState([]);
    // const [galleryActiveIndex, setGalleryActiveIndex] = useState(0);
    const view_mode = props.viewMode;
    const { i18n, t } = useTranslation();

    // const[userImage, setUserImage] = useState(userLogged.file? 'http://127.0.0.1:8000'+userLogged.file : 'https://imgur.com/E0h3MGt.png')

    useEffect(() => {
        // if(!props.preview){
        // getUserData();
        if (userLogged) {
            //getPostFavorite();
           // getMatched();
            getPostTags();
        } else {
            getPostTags();
        }
        // getPostLikes();
        // getPostFavorite();
        
        // getPostGallery();
        // }
    }, [props.post]);

    // async function getUserData(){
    //   // axios.get('http://localhost:8000/api/users/1')
    //   //     .then(function (response) {
    //   //         console.log("response", response.data);
    //   //         setUser(response.data);
    //   //     })
    //   //     .catch(function (error) {
    //   //         // handle error
    //   //         console.log(error);
    //   //     })

    //     const res = await axios.get(window.REACT_APP_API_ENDPOINT+'/api/users/'+props.post.user_id);

    //   let data = await res.data;
    //   console.log("useeer",data);
    //   setUser(data);
    // }

    async function getPostTags() {
        const res = await axiosInstance.get(
            window.REACT_APP_API_ENDPOINT +
                "/api/posts/" +
                props.post.id +
                "/tags"
        );

        let data = await res.data;
        setTags(data);
    }

    //   async function getPostGallery(){
    //     const res = await axiosInstance.get(window.REACT_APP_API_ENDPOINT+'/api/posts/'+props.post.id+'/gallery');

    //     let data = await res.data;
    //     console.log("gallery",data);
    //     setPostGallery(data);
    // }

    async function getPostFavorite() {
        const res = await axiosInstance.get(
            window.REACT_APP_API_ENDPOINT +
                "/api/postfavorites/checkFavorite/" +
                userLogged.id +
                "/" +
                props.post.id
        );

        let data = await res.data;
        if (data.length > 0) {
            setFavorite(true);
            setPostFavorite(data[0]);
        }
    }

    async function getMatched () {
        const res = await axiosInstance.get(
            window.REACT_APP_API_ENDPOINT +
                "/api/getMatched/" +
                userLogged.id +
                "/" +
                props.post.id
        );

        let data = await res.data;
        //console.log(props.post.id,data)    
        if (data) {
            if (data.favorite.length > 0) {
                setFavorite(true);
                setPostFavorite(data.favorite[0]);
            }
            setMatched(data);
            
        }
        setIsLoading(false);
    }

    // async function getPostLikes(){
    //   console.log("props post", props.post.id);

    //     const res = await axiosInstance.get(window.REACT_APP_API_ENDPOINT+'/api/posts/'+props.post.id+'/likes');

    //   let data = await res.data;
    //   if(data.length>0){
    //     setPostLikes(data);
    //   }
    //   console.log("post likes all",data);
    // }

    function onPostDelete(e) {
        e.preventDefault();
        axiosInstance
            .delete(
                `${window.REACT_APP_API_ENDPOINT}/api/posts/${props.post.id}`
            )
            .then((res) => {
                console.log(res);
                console.log(res.data);
                setShowDeleteModal(false);
                window.location.reload();
            });
    }

    function onPostArchive(e) {
        e.preventDefault();

        if (archived) {
            axiosInstance
                .put(
                    window.REACT_APP_API_ENDPOINT +
                        "/api/posts/" +
                        props.post.id +
                        "/unarchive"
                )
                .then((response) => {
                    console.log(response);
                    if (response.status == 200) {
                        setArchived(false);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response);
                        console.log("server responded");
                    } else if (error.request) {
                        console.log("network error");
                    } else {
                        console.log(error);
                    }
                });
        } else {
            axiosInstance
                .put(
                    window.REACT_APP_API_ENDPOINT +
                        "/api/posts/" +
                        props.post.id +
                        "/archive"
                )
                .then((response) => {
                    console.log(response);
                    if (response.status == 200) {
                        setArchived(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response);
                        console.log("server responded");
                    } else if (error.request) {
                        console.log("network error");
                    } else {
                        console.log(error);
                    }
                });
        }

        window.location.reload();
    }

    function onPostFavorite(e) {
        e.preventDefault();
        if (favorite == false) {
            const data = {
                user_id: userLogged.id,
                post_id: props.post.id,
            };

            axiosInstance
                .post(
                    window.REACT_APP_API_ENDPOINT + "/api/postfavorites",
                    data
                )
                .then((response) => {
                    console.log(response);

                    setFavorite(true);
                    setPostFavorite(response.data.post);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response);
                        console.log("server responded");
                    } else if (error.request) {
                        console.log("network error");
                    } else {
                        console.log(error);
                    }
                });
        } else {
            axiosInstance
                .delete(
                    `${window.REACT_APP_API_ENDPOINT}/api/postfavorites/${postFavorite.id}`
                )
                .then((res) => {
                    console.log(res);
                    console.log(res.data);
                    setFavorite(false);

                    // const posts = postLikes.filter(item => item.id !== postLike.id);
                    // setPostLikes(posts);
                });
        }
    }

    // function onPrevClick(){
    //   if(galleryActiveIndex != 0){
    //     setGalleryActiveIndex((galleryActiveIndex) => galleryActiveIndex - 1);
    //   }
    //   else{
    //     setGalleryActiveIndex(postGallery.length);
    //   }
    // }

    // function onNextClick(){
    //   if(galleryActiveIndex != postGallery.length){
    //     setGalleryActiveIndex((galleryActiveIndex) => galleryActiveIndex + 1);
    //   }
    //   else{
    //     setGalleryActiveIndex(0);
    //   }
    // }

    function onPostClick() {
        setShowPostModal(true);
    }

    function onPostModalClick() {
        setShowPostModal(false);
    }
    function matchedClass(matched_val) {
        //console.log(isLoading,matched_val)
        if (isLoading) {
            return "bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg cursor-pointer";
        } else {
            let className = 'bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg cursor-pointer';
            //console.log('load done', matched_val)
            const matched_val2 = matched_val
            switch (matched_val2.found) {
                case 0:
                className += ' class-for-0';
                break;
                case 1:
                className += ' class-for-1';
                break;
                case 2:
                className += ' class-for-2';
                break;
                case 3:
                className += ' class-for-3';
                break;
                default:
                break;
            }
            return className;
        }
        
    }

    function matchedClass2(found) {
        
            let className = 'bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg cursor-pointer';
            //console.log('load done', matched_val)
            switch (found) {
                case 0:
                className += ' class-for-0';
                break;
                case 1:
                className += ' class-for-1';
                break;
                case 2:
                className += ' class-for-2';
                break;
                case 3:
                className += ' class-for-3';
                break;
                default:
                break;
            }
            return className;
        
    }

    function matchedName(matched_val) {
        
        if (isLoading) {
            return "";
        } else {
            let names = '';
            
            const matched_val3 = matched_val
            switch (matched_val3.found) {
                case 0:
                names += '';
                break;
                case 1:
                names += matched_val3.names +' visited this hotel';
                break;
                case 2:
                //names += matched_val3.names +' visited this region';
                break;
                case 3:
                //names += matched_val3.names +' visited this country';
                break;
                default:
                break;
            }
            return names;
        }
        
    }

    function matchedName2(matched_val3, found) {
        
            let names = '';
            switch (found) {
                case 0:
                names += '';
                break;
                case 1:
                names += matched_val3 +' visited this hotel';
                break;
                case 2:
                //names += matched_val3 +' visited this region';
                break;
                case 3:
                //names += matched_val3 +' visited this country';
                break;
                default:
                break;
            }
            return names;
        
        
    }
    let content;
    //return (
        if (view_mode === "grid") {
            content =(
        <li className={matchedClass2(props.post.found)}>
            {showPostModal && (
                <PostModal
                    post={props.post}
                    liked={liked}
                    tags={tags}
                    onCloseClick={onPostModalClick}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    title="Are you sure you want to delete this post?"
                    cancelButton="Cancel"
                    confirmButton="Delete"
                    onConfirmClick={onPostDelete}
                />
            )}
            <article aria-labelledby={"question-title-"}>
                <div>
                    <div className="flex space-x-3">
                        <div className="flex-shrink-0">
                            <img
                                className="h-8 w-8 rounded-full object-cover"
                                src={
                                    props.post.image
                                        ? 
                                          props.post.image
                                        : "https://imgur.com/E0h3MGt.png"
                                }
                                alt=""
                            />
                        </div>
                        <div className="min-w-0 flex-1">
                            <p className="text-sm font-medium text-gray-900 text-left">
                                <a
                                    href={
                                        "/" +
                                        i18n.language +
                                        "/profile/" +
                                        props.post.username
                                    }
                                    className="hover:underline"
                                >
                                    {props.post.username}
                                </a>
                            </p>
                            <p className="text-sm text-gray-500 text-left flex">
                                <span className="inline-block w-3/4">
                                    {props.post.location
                                        ? props.post.location
                                        : ""}
                                </span>
                                <span className="inline-block	w-1/4 text-right font-bold">
                                    {props.post.hotel_price
                                        ? props.post.hotel_price + "€"
                                        : ""}
                                </span>
                            </p>
                        </div>
                        {props.type == "profile" && (
                            <div className="flex-shrink-0 self-center flex">
                                <Menu
                                    as="div"
                                    className="relative inline-block text-left"
                                >
                                    <div>
                                        <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                            <span className="sr-only">
                                                Open options
                                            </span>
                                            <DotsVerticalIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </Menu.Button>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
                                            <div className="py-1">
                                                {/* {props.type == "feed" && <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            className={classNames(
                                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                              'flex px-4 py-2 text-sm'
                                            )}
                                          >
                                            <CodeIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            <span>Embed</span>
                                          </a>
                                        )}
                                      </Menu.Item>} */}
                                                {/* {props.type == "feed" && <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            className={classNames(
                                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                              'flex px-4 py-2 text-sm'
                                            )}
                                          >
                                            <FlagIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            <span>Report content</span>
                                          </a>
                                        )}
                                      </Menu.Item>} */}
                                                <>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href={
                                                                    "/" +
                                                                    i18n.language +
                                                                    "/post-form/" +
                                                                    props.post
                                                                        .id
                                                                }
                                                                className={classNames(
                                                                    active
                                                                        ? "bg-gray-100 text-gray-900"
                                                                        : "text-gray-700",
                                                                    "flex px-4 py-2 text-sm"
                                                                )}
                                                            >
                                                                <PencilAltIcon
                                                                    className="mr-3 h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                                <span>
                                                                    Edit
                                                                </span>
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                onClick={() => {
                                                                    setShowDeleteModal(
                                                                        true
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    active
                                                                        ? "bg-gray-100 text-gray-900"
                                                                        : "text-gray-700",
                                                                    "flex px-4 py-2 text-sm"
                                                                )}
                                                            >
                                                                <TrashIcon
                                                                    className="mr-3 h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                                <span>
                                                                    Delete
                                                                </span>
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                onClick={
                                                                    onPostArchive
                                                                }
                                                                className={classNames(
                                                                    active
                                                                        ? "bg-gray-100 text-gray-900"
                                                                        : "text-gray-700",
                                                                    "flex px-4 py-2 text-sm"
                                                                )}
                                                            >
                                                                <ArchiveIcon
                                                                    className="mr-3 h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                                <span>
                                                                    {archived
                                                                        ? "Unarchive"
                                                                        : "Archive"}
                                                                </span>
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                </>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className="mt-2 text-sm text-gray-700 space-y-4"
                    onClick={onPostClick}
                >
                    <div className="overflow-hidden relative h-56 sm:h-64 xl:h-80 2xl:h-96">
                        <img
                            className="block h-full object-cover w-full"
                            src={
        
                                props.post.file
                            }
                            alt=""
                        />
                        <p className="text-sm font-medium text-gray-900 text-left">
                                <a
                                    href={
                                        "/" +
                                        i18n.language +
                                        "/profile/" +
                                        props.post.username
                                    }
                                    className="hover:underline"
                                >
                                    {props.post.username}
                                </a>
                            </p>
                    </div>
                    {/* {postGallery && postGallery.length>0 && <div id="default-carousel" className="relative" data-carousel="static"> */}

                    {/* <div className="overflow-hidden relative h-56 sm:h-64 xl:h-80 2xl:h-96"> */}

                    {/* {postGallery.map((img, index)=>
                              <div className={`duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-10 ${galleryActiveIndex == index? 'block':'hidden'}`} data-carousel-item={index}>
                                <img src={window.REACT_APP_API_ENDPOINT+""+img.file} className="block h-full object-cover w-full" alt="..."/>
                              </div>
                            )} */}
                    {/* <div className={`duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-10 block`}>
                              <img className="block h-full object-cover w-full" src={window.REACT_APP_API_ENDPOINT+""+props.post.file} alt="" />
                            </div> */}

                    {/* </div> */}

                    {/* <button onClick={onPrevClick} type="button" className="flex absolute top-0 left-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none" data-carousel-prev="">
                            <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                            <svg className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
                            <span className="hidden">Previous</span>
                            </span>
                            </button>
                            <button onClick={onNextClick} type="button" className="flex absolute top-0 right-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none" data-carousel-next="">
                            <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                            <svg className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                            <span className="hidden">Next</span>
                            </span>
                            </button>
                            </div>} */}
                    {/* <img class="w-full bg-cover" src={props.preview? URL.createObjectURL(props.post.file) : "http://127.0.0.1:8000"+props.post.file}/> */}

                    {/* <p className="text-sm text-gray-500 text-left mb-4">
                  
                              <span>
                                  {props.post.hotel_name? props.post.hotel_name : ''}
                              </span>
                              {props.post.hotel_name && props.post.hotel_price && <span className="inline-block w-2 h-2 rounded-full bg-gray-500 mr-2 ml-2"></span>}
                              <span>
                                  {props.post.hotel_price? props.post.hotel_price + '€' : ''}
                              </span>
                           </p> */}
                    <p className="text-left">{props.post.caption}</p>

                    {tags.length > 0 &&
                        tags.map((tag) => (
                            <a className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                #{tag.name.en}{" "}
                            </a>
                        ))}
                    <p className="text-left red-color">{matchedName2(props.post.names, props.post.found)}</p>    
                </div>
                <div className="mt-6 flex justify-between space-x-8">
                    <div className="flex space-x-6">
                        <span className="inline-flex items-center text-sm">
                            <button
                                type="button"
                                className="inline-flex space-x-2 text-gray-400 hover:text-gray-500"
                                onClick={onPostFavorite}
                            >
                                {/* <HeartIcon className="h-5 w-5" aria-hidden="true" color={liked? "red": "currentColor"}/> */}
                                <HeartIcon
                                    className="mr-3 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                    fill={props.post.favorite.length || favorite ? "yellow" : "grey"}
                                />
                                {/* <span className="font-medium text-gray-900">{postLikes.length}</span> */}
                                {/* <span className="sr-only">likes {liked? "test":""}</span> */}
                            </button>
                        </span>
                        <a href={"whatsapp://send?text=Please see this hotel: "+ props.post.location +". See image: "+props.post.file}  rel="nofollow noopener" target="_blank" className="ml-2"> <ShareIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill="grey"
                        /></a>
                    </div>
                    <div className="flex text-sm">
                        <span className="inline-flex items-center text-sm">
                            <a
                                href={props.post.hotel_link}
                                target="_blank"
                                className="inline-flex space-x-2 text-gray-400 hover:text-gray-500"
                            >
                                <ChevronDoubleRightIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />

                                <span className="font-medium text-gray-900">
                                    {t("post.see_hotel")}
                                </span>
                            </a>
                        </span>
                    </div>
                </div>
                
            </article>
        </li>
    //);
    ); 
    } else {
                content = (
                    <li className={`${matchedClass2(props.post.found)} bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg cursor-pointer`}>
                    {showPostModal && (
                        <PostModal
                            post={props.post}
                            liked={liked}
                            tags={tags}
                            onCloseClick={onPostModalClick}
                        />
                    )}
                    {showDeleteModal && (
                        <ConfirmModal
                            title="Are you sure you want to delete this post?"
                            cancelButton="Cancel"
                            confirmButton="Delete"
                            onConfirmClick={onPostDelete}
                        />
                    )}
                    <article className="flex w-3/4">
                        {/* Hotel Image on the left */}
                        
                        <div className="flex-shrink-0 w-1/4">
                            <img 
                                className="h-250 w-250 object-cover rounded-lg listImage" 
                                src={props.post.file || "https://via.placeholder.com/250"} 
                                alt={props.post.title} 
                            />
                        </div>
                               {/* Hotel Details on the right */}
                 
                        <div className="flex-1 ml-8">
                            <div className="flex items-center">
                                <img
                                    className="h-8 w-8 rounded-full object-cover"
                                    src={props.post.image ? props.post.image : "https://imgur.com/E0h3MGt.png"}
                                    alt=""
                                />
                                <p className="ml-2 text-sm font-medium text-gray-900">
                                    <a
                                        href={"/" + i18n.language + "/profile/" + props.post.username}
                                        className="hover:underline"
                                    >
                                        {props.post.username}
                                    </a>
                                </p>
                            </div>
                        
                            <h2 className="text-xl font-semibold text-gray-900">{props.post.caption}</h2>
                            <p className="text-md text-gray-500 mt-2 mb-2">{props.post.location || "Location not specified"}</p>
                           
                            {tags.length > 0 &&
                        tags.map((tag) => (
                            <a className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                #{tag.name.en}{" "}
                            </a>
                        ))}
                            {/* Footer Section */}
                            <div className="  left-2 flex space-x-2 mt-2">
                                <span className="inline-flex items-center text-sm">
                                    <button
                                        type="button"
                                        className="inline-flex space-x-2 text-gray-400 hover:text-gray-500"
                                        onClick={onPostFavorite}
                                    >

                                    <HeartIcon
                                        className="mr-3 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                        fill={props.post.favorite.length || favorite ? "yellow" : "grey"}
                                    />
            
                                </button>
                                </span>
                                <a href={"whatsapp://send?text=Please see this hotel: "+ props.post.location +". See image: "+props.post.file}  rel="nofollow noopener" target="_blank" className="ml-2"> <ShareIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                    fill="grey"
                                /></a>
                            </div>
                            
                        </div>
                    </article>
                    <div className="mt-4 flex justify-between items-right see_hotel">
                                
                                <a
                                    href={props.post.hotel_link}
                                    target="_blank"
                                    className="inline-flex space-x-2 text-gray-400 hover:text-gray-500"
                                >
                                    <ChevronDoubleRightIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />

                                    <span className="font-medium text-gray-900">
                                        {t("post.see_hotel")}
                                    </span>
                                </a>
                                
                            </div>
                </li>
                        
                );
    }
    return content;
}

export default Post;
